<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="field">
        <div class="box">
            <div class="tit">
                <ul>
                    <li v-for='(i,index) in list' :key='index' @click="dian_list(index)" :class="{ 'bei':index == inde}">{{i}}</li>
                </ul>
            </div>
            <p class="p1">{{title}}</p>
            <div class="huixian">
                <div>
                    <span @click='hangdian' style="background:#F96C6C;color:#fff;border:none;" v-if="xinziduan">添加新字段</span>
                    <span>调整顺序</span>
                </div>
                <span v-if="xinziduan" style="margin-right:0.12rem;" @click="dianji_shanchu">批量删除</span>
            </div>
            <div>
                <el-table
                    :data="tableData"
                    cell-style="color:#4C4A4D;font-size:0.12rem;" header-cell-style="color:#1A2533;"
                    style="width: 100%"
                    @selection-change="handleSelectionChangea"
                    @row-click='hangdiana'
                    v-if="!xinziduan">
                    <el-table-column
                        prop="field_name"
                        label="字段"
                        width="380">
                    </el-table-column>
                    <el-table-column
                        label="转化字段"
                        width="320">
                        <template slot-scope="scope" v-if="!xinziduan">
                            <span type="text" size="small" :style="scope.row.wei=='转化'?'color:#F96C6C':'color:#6ABFA7;'">{{scope.row.wei}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="field_convert"
                        label="新字段"
                        width="220">
                    </el-table-column>
                </el-table>
            </div>
            <el-table
                :data="tableData"
                cell-style="color:#4C4A4D;font-size:0.12rem;" header-cell-style="color:#1A2533;"
                style="width: 100%"
                @selection-change="handleSelectionChange"
                v-if="xinziduan">
                <el-table-column
                type="selection"
                width="55">
                </el-table-column>
                <el-table-column
                    prop="field_name"
                    label="字段"
                    width="480">
                </el-table-column>
                <el-table-column
                    prop="operate_time"
                    label="添加日期"
                    width="380">
                </el-table-column>
            </el-table>
            <el-drawer
                :visible.sync="drawer"
                :modal='false'
                :show-close='false'
                title="字段库"
                size="620">
                <div style="color:#888888;font-size:0.18rem;height:0.42rem;width:94%;background:#E7E8EB;margin:0 auto;line-height:0.42rem;padding:0 0.12rem;min-width:3.88rem;">字段信息</div>
                <ul class="liebiao">
                    <li class="dd1">
                        <span>*</span>默认字段名称
                    </li>
                    <li class="dd2"><p>{{name_tit}}</p></li>
                </ul>
                <ul class="liebiao">
                    <li class="dd1">
                        <span>*</span>新字段名称
                    </li>
                    <li class="dd2"><input type="text" placeholder='请输入新字段' v-model="name_tit2"></li>
                </ul>
                <div class="xuan_foot">
                    <!-- <div v-if="xiugai==0" style="color:#fff;background:#F96C6C" @click="box_sure">添加</div>
                    <div v-if="xiugai==0" style="color:#fff;background:#F96C6C" @click="box_jixu">继续添加</div> -->
                    <div style="color:#fff;background:#F96C6C" @click="box_queding">确认</div>
                    <div style="color:#888888;border:0.01rem solid #888888" @click="drawer = false">取消</div>
                </div>
            </el-drawer>
            <el-drawer
                :visible.sync="drawera"
                :modal='false'
                :show-close='false'
                title="字段库"
                size="620">
                <div style="color:#888888;font-size:0.18rem;height:0.42rem;width:94%;background:#E7E8EB;margin:0 auto;line-height:0.42rem;padding:0 0.12rem;min-width:3.88rem;">字段信息</div>
                <ul class="liebiao">
                    <li class="dd1">
                        <span>*</span>新字段名称
                    </li>
                    <li class="dd2"><input type="text" placeholder='请输入新字段' v-model="name_tit3"></li>
                </ul>
                <div class="xuan_foot">
                    <!-- <div v-if="xiugai==0" style="color:#fff;background:#F96C6C" @click="box_sure">添加</div>
                    <div v-if="xiugai==0" style="color:#fff;background:#F96C6C" @click="box_jixu">继续添加</div> -->
                    <div style="color:#fff;background:#F96C6C" @click="box_tianjia">添加</div>
                    <div style="color:#888888;border:0.01rem solid #888888" @click="drawera = false">取消</div>
                </div>
            </el-drawer>
        </div>
    </div>
</template>

<script>
import { query_field_base, update_field_base, insert_field_base, delete_field_base_batch } from '../../api/api.js'
export default {
  name: 'field',
  data () {
    return {
      ent_id: '',
      list: [
        '目标',
        '实际业绩',
        '客户拜访',
        '费用',
        '毛利',
        '预测业绩',
        '基础信息',
        '产品',
        '客户',
        '科目',
        '时间',
        '自定义'
      ],
      inde: 0,
      tableData: [],
      title: '目标',
      xinziduan: false,
      drawer: false,
      multipleSelection: '',
      drawera: false,
      multipleSelectiona: '',
      name_tit: '',
      name_tit2: '',
      name_tit3: '',
      field_type: '1',
      zhuan_sure: '',
      jichuxinxi: ''
    }
  },
  mounted () {
    this.jichu()
  },
  created () {
    if (sessionStorage.getItem('pc_mlbb_ent_info') != null && sessionStorage.getItem('pc_mlbb_ent_info') != undefined && sessionStorage.getItem('pc_mlbb_ent_info') != '') {
      this.ent_id = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_id
    }
    if (sessionStorage.getItem('pc_mlbb_jichuxinxi') != null && sessionStorage.getItem('pc_mlbb_jichuxinxi') != undefined && sessionStorage.getItem('pc_mlbb_jichuxinxi') != '') {
      this.jichuxinxi = JSON.parse(JSON.parse(sessionStorage.getItem('pc_mlbb_jichuxinxi')))
    }
  },
  methods: {
    //   基础文字信息
    jichu () {
      query_field_base({
        data: {
          ent_id: this.ent_id,
          user_id: this.jichuxinxi.user_id,
          field_type: this.field_type
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10121) {
          const date = JSON.parse(res.data.body.data)
          for (let i = 0; i < date.length; i++) {
            if (date[i].field_convert == undefined) {
              date[i].wei = '转化'
            } else {
              date[i].wei = '已转化'
            }
          }
          console.log(date)
          this.tableData = date
        } else if (res.data.code == 10122) {
                    
        }
      })
    },
    dian_list (index) {
      this.inde = index
      this.title = this.list[index]
      if (index == 11) {
        index = -1
        this.xinziduan = true
      } else {
        this.xinziduan = false
      }
      this.field_type = index + 1 + ''
      query_field_base({
        data: {
          ent_id: this.ent_id,
          user_id: this.jichuxinxi.user_id,                
          field_type: index + 1 + ''
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10121) {
          const date = JSON.parse(res.data.body.data)
          for (let i = 0; i < date.length; i++) {
            if (date[i].field_convert == undefined) {
              date[i].wei = '转化'
            } else {
              date[i].wei = '已转化'
            }
          }
          console.log(date)
          this.tableData = date
        } else if (res.data.code == 10122) {
                    
        }
      })
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
      console.log(9999999, val)
    },
    // 行点
    hangdian (val) {
      this.drawera = true
      this.name_tit3 = ''
    },
    handleSelectionChangea (val) {
      this.multipleSelectiona = val
      console.log(9999999, val)
    },
    // 行点
    hangdiana (val) {
      this.drawer = true
      console.log(val)
      this.zhuan_sure = val
      this.name_tit = val.field_name
      this.name_tit2 = val.field_convert
    },
    // 转化字段
    box_queding () {
      console.log(this.zhuan_sure)
      if (this.name_tit != '' && /^(?:[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0])+$/.test(this.name_tit2)) {
        update_field_base({
          data: {
            id: this.zhuan_sure.id + '',
            field_name: this.zhuan_sure.field_name,
            field_type: this.field_type,
            field_convert: this.name_tit2,
            ent_id: this.ent_id,
            user_id: this.jichuxinxi.user_id,
            field_define: this.zhuan_sure.field_define
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10119) {
            this.zhuan_sure = ''
            this.drawer = false
            this.jichu()
            this.$message({
              message: '修改成功',
              type: 'success'
            }) 
          } else if (res.data.code == 10120) {
          }
        })
      } else {
        this.$message({
          message: '请填写要编辑的信息，且必须为汉字',
          type: 'warning'
        }) 
      }
    },
    // 添加新字段
    box_tianjia () {
      console.log(this.name_tit3)
      if (this.name_tit3 != '' && /^(?:[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0])+$/.test(this.name_tit3)) {
        insert_field_base({
          data: {
            field_name: this.name_tit3,
            ent_id: this.ent_id,
            user_id: this.jichuxinxi.user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10117) {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.jichu()
            this.drawera = false
            this.name_tit3 = ''
          } else if (res.data.code == 10118) {
          } else if (res.data.code == 10174) {
            this.$message({
              message: '已存在该字段',
              type: 'warning'
            }) 
          }
        })
      } else {
        this.$message({
          message: '请填写新字段，且必须为汉字',
          type: 'warning'
        }) 
      }
    },
    // 点击批量删除
    dianji_shanchu () {
      if (this.multipleSelection.length != 0) {
        let ids = []
        for (let i = 0; i < this.multipleSelection.length; i++) {
          ids.push(this.multipleSelection[i].id)
        }
        ids = ids.join(',')
        delete_field_base_batch({
          data: {
            id: ids,
            ent_id: this.ent_id,
            user_id: this.jichuxinxi.user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10123) {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.jichu()
          } else if (res.data.code == 10124) {}
        })
      } else {
        this.$message({
          message: '请选择要删除的字段',
          type: 'warning'
        }) 
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.field {
    height:100%;
    width:100%;
    .box{
        padding:  0 0.29rem;
        .tit{
            ul{
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 0.3rem;
                li{
                    height:0.36rem;
                    width:0.8rem;
                    background-color: #E7E8EB;
                    font-size: 0.14rem;
                    color:#888888;
                    line-height: 0.36rem;
                    text-align: center;
                    border-right:0.01rem solid #fff;
                    // border-radius: 0.02rem;
                    &:nth-child(1){
                        border-radius: 0.03rem 0 0 0.03rem;
                    }
                    &:nth-child(12){
                        border-radius: 0 0.03rem 0.03rem 0;
                    }
                    cursor:pointer;
                }
                .bei{
                    background: #F96C6C;
                    color:#fff;
                    font-size: 0.14rem;
                    border-right:0.01rem solid #fff;
                }
            }
        }
        .p1{
            font-size: 0.18rem;
            color:#1A2533;
            margin-top: 0.25rem;
            margin-bottom: 0.1rem;
        }
        .huixian{
            height:0.42rem;
            background:#E7E8EB;
            display: flex;
            align-items: center;
            justify-content: space-between;
            div{
                display: flex;
                align-items: center;
            }
            span{
                font-size: 0.12rem;
                color:#888888;
                padding: 0.04rem 0.12rem;
                border:0.01rem solid #A9A9A9;
                background: #fff;
                border-radius: 0.02rem;
                margin-left:0.12rem;
                display: block;
                cursor:pointer;
            }
        }
    }
}
</style>
